.react-toggle-thumb {
  left: 2px;
}

.react-toggle-track {
  background-color: #c7c7c7;
}

.react-toggle--checked .react-toggle-track {
  background-color: #009245;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #009245;
}
