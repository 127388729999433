@import '~react-toastify/dist/ReactToastify.min.css';

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #007d8a;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #b71e3f;
}

* {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

a,
img {
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
}

a.link-base,
a.link-base:hover,
a.link-base:focus {
  text-decoration: none !important;
  color: inherit !important;
}

.mb-base {
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
}

.center-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0;
  background: transparent;
  overflow: -moz-scrollbars-none;
}

.Toastify__toast-container {
  background-color: transparent !important;
}

.Toastify__toast-container--top-center {
  top: 40px !important;
}

.Toastify__toast {
  width: calc(100% - 40px);
  margin: 0 auto;
  border-radius: 10px;
  align-items: center;
  padding: 12px 20px;
}

.Toastify__toast-body {
  font-size: 18px;
  line-height: 23px;
  padding: 0;
  padding-right: 20px;
}

.Toastify__close-button {
  opacity: 1;
  align-self: auto;
}

.Toastify__close-button > svg {
  width: 20px;
  height: auto;
}

.Toastify__toast--app-dark .Toastify__toast-body,
.Toastify__toast--app-dark .Toastify__close-button {
  color: #222;
}
