body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: 'Adobe-sketchnote';
  src: local('Adobe-sketchnote'),
  url(./fonts/adobe-sketchnote.woff) format('woff');
}

@font-face {
  font-family: 'Boston-Angel-Bold';
  src: local('Boston-Angel-Bold'),
  url(fonts/boston-angel-bold.otf) format('woff');
}

@font-face {
  font-family: 'SFUIText-Pro2';
  src: url(./fonts/SFUIText-Pro.ttf) format('woff');
}

@font-face {
  font-family: 'Transit-Pro';
  src: url(./fonts/transit-pro.otf) format('woff');
}

@font-face {
  font-family: 'Transit-Pro';
  src: url(./fonts/transit-pro-bold.otf) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'TheMixOsF-Black';
  src: url(./fonts/the-mix-osf-black.otf) format('woff');
}
